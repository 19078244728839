import { ref, watch } from '@vue/composition-api'
import {
  useTableComponent, useCommenSettings, useAlert,
} from '@/libs/mixins/index'
import store from '@/store'
import { useApiList } from '@/views/admin/api/mail/useApi'
// import router from '@/router'

export const useModalSetting = () => {
  const {
    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  } = useCommenSettings()

  const isBusy = ref(false)

  const ui = {}

  const getMetaListData = (...arg) => store.dispatch('api/getMetaList', ...arg)

  return {
    ui,
    isBusy,

    getMetaListData,

    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  }
}

export const useRemarkModal = () => {
  const ui = {}
  const {
    syncObject,
  } = useCommenSettings()

  const {
    totalNum,
    currentPage,
    perPage,
    searchQuery,
    timeRange,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const setRemarkCreate = (...arg) => store.dispatch('api/setRemarkCreate', ...arg)
  const setRemarkUpdateContent = (...arg) => store.dispatch('api/setRemarkUpdateContent', ...arg)
  const setRemarkUpdateDisplay = (...arg) => store.dispatch('api/setRemarkUpdateDisplay', ...arg)
  const setRemarkClearDisplay = (...arg) => store.dispatch('api/setRemarkClearDisplay', ...arg)
  const setRemarkDelete = (...arg) => store.dispatch('api/setRemarkDelete', ...arg)

  perPage.value = 10

  const inputRemarkTextarea = ref(null)
  const isBusy = ref(false)
  const isInitBusy = ref(true)
  const isLoadingBusy = ref(false)
  const inputRemark = ref('')
  const remarkList = ref([])
  const filterSelected = ref()
  const filterOptions = ref([])
  const keySelected = ref('all')
  const keyOptions = ref([])
  const propsKey = ref([])
  const searchSelf = ref(false)
  const searchDisplay = ref(false)
  const userData = ref(null)

  const transKey = {
    customer: '會員',
    order: '訂單',
    domain: '網域服務',
    vps: 'VPS',
    host: '實體主機',
    quota: '點數紀錄',
  }

  // const blankUserData = {
  //   id: 2,
  //   ssid: null,
  //   account: 'super.hannah1022@gmail.com',
  //   image: 'http://10.132.162.3/customer/image/0Phk80em5PibO6AsKBZoYCoIQPu1bqBU',
  //   name: '九尾貓',
  // }

  const blankUserData = {
    id: null,
    ssid: null,
    account: null,
    image: null,
    name: null,
  }

  const blankRemarkData = {
    id: null,
    key: null,
    admin: null,
    content: '',
    ip: null,
    display: false,
    updated_at: null,
    created_at: null,
    deleted_at: null,
  }

  const resolveFilterType = type => {
    if (type === 'all') {
      return {
        label: '全部',
        value: type,
        color: '#2671c7',
      }
    }
    if (type === 'customer') {
      return {
        label: '會員',
        value: type,
        color: '#b46de3',
      }
    }
    if (type === 'order') {
      return {
        label: '訂單',
        value: type,
        color: '#2eb358',
      }
    }
    if (type === 'domain') {
      return {
        label: '網域服務',
        value: type,
        color: '#8bced6',
      }
    }
    if (type === 'vps') {
      return {
        label: 'VPS',
        value: type,
        color: '#e6c019',
      }
    }
    if (type === 'host') {
      return {
        label: '實體主機',
        value: type,
        color: '#cc0836',
      }
    }
    if (type === 'quota') {
      return {
        label: '點數紀錄',
        value: type,
        color: '#ff960d',
      }
    }
    return false
  }

  const getRemarkListData = () => {
    let resolveFilters = ''

    if (propsKey.value.length > 0) {
      const resolveKey = 'key: '
      if (keySelected.value === 'all') resolveFilters = resolveKey + propsKey.value.join(', ')
      else {
        const data = propsKey.value.find(item => item.startsWith(keySelected.value))
        resolveFilters = resolveKey + data
      }
    }

    if (timeRange.value !== null) {
      resolveFilters = `${resolveFilters};created_at: ${timeRange.value}`
    }

    if (searchSelf.value) {
      resolveFilters = `${resolveFilters};admin: ${userData.value.id}`
    }

    if (searchDisplay.value) {
      resolveFilters = `${resolveFilters};display: 1`
    }

    isLoadingBusy.value = true
    store.dispatch('api/getRemarkList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      // sort: sortBy.value,
      // order: isSortDirDesc.value ? 'desc' : 'asc',
      range: timeRange.value,
      filters: resolveFilters,
    })
      .then(response => {
        const { data, total } = response.data.data
        const userInfo = JSON.parse(localStorage.getItem('userData'))
        userData.value = syncObject(blankUserData, userInfo)
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankRemarkData, item),
            admin_info: item.admin && item.admin_info ? syncObject(blankUserData, item.admin_info) : syncObject(blankUserData, {}),
          }
          const [key] = resolve.key.split('-')
          resolve.label = key
          return resolve
        })
        resolveData.forEach(el => {
          remarkList.value.push(el)
        })
        totalNum.value = total
        isLoadingBusy.value = false
        isInitBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
        isInitBusy.value = false
      })
  }

  watch([currentPage], () => {
    if (!isInitBusy.value) getRemarkListData()
  })

  watch([timeRange, searchQuery, searchSelf, searchDisplay, keySelected], () => {
    if (isInitBusy.value) return
    isInitBusy.value = true
    currentPage.value = 1
    remarkList.value = []
    getRemarkListData()
  })

  return {
    ui,
    useAlertToast,
    useHttpCodeAlert,
    blankRemarkData,
    blankUserData,
    totalNum,
    currentPage,
    perPage,
    searchQuery,
    timeRange,
    inputRemarkTextarea,
    isBusy,
    transKey,
    propsKey,
    isInitBusy,
    isLoadingBusy,
    filterSelected,
    filterOptions,
    keySelected,
    keyOptions,
    inputRemark,
    remarkList,
    searchSelf,
    searchDisplay,
    userData,
    resolveFilterType,
    getRemarkListData,
    setRemarkCreate,
    setRemarkUpdateContent,
    setRemarkUpdateDisplay,
    setRemarkClearDisplay,
    setRemarkDelete,
  }
}

export const useAdminApiMailModal = () => {
  const tableData = ref([])
  const isTableBusy = ref(true)

  const {
    blankMailData,
  } = useApiList()

  const {
    syncObject,
  } = useModalSetting()

  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
  } = useTableComponent()

  const {
    useHttpCodeAlert,
  } = useAlert()

  tableColumns.value = [
    { label: '#編號', key: 'id' },
    { label: '名稱', key: 'name' },
    { label: '設定', key: 'use' },
    { label: '動作', key: 'actions' },
  ]

  const setAPIMailDelete = (...arg) => store.dispatch('admin-api-mail-modal/setAPIMailDelete', ...arg)
  const setAPIMailCreate = (...arg) => store.dispatch('admin-api-mail-modal/setAPIMailCreate', ...arg)
  const setAPIMailUpdate = (...arg) => store.dispatch('admin-api-mail-modal/setAPIMailUpdate', ...arg)

  const getAPIMailListData = () => {
    isTableBusy.value = true
    store.dispatch('admin-api-mail-modal/getAPIMailList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      // filters: resolveFilters,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankMailData, item),
          }
          return resolve
        })
        tableData.value = resolveData
        totalNum.value = total
        isTableBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else getAPIMailListData()
  }

  watch([currentPage, perPage, searchQuery], () => {
    getAPIMailListData()
  })

  return {
    tableData,
    isTableBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    refetchTable,

    setAPIMailDelete,
    setAPIMailCreate,
    setAPIMailUpdate,
    getAPIMailListData,
    useHttpCodeAlert,
  }
}

export const useAdminDomainDealerModal = () => {
  const tableData = ref([])
  const domainAgentOptions = ref([])
  const isTableBusy = ref(true)

  const {
    syncObject,
    getMetaListData,
  } = useModalSetting()

  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  tableColumns.value = [
    { label: '#編號', key: 'id' },
    { label: '名稱', key: 'name' },
    { label: '設定', key: 'use' },
  ]

  const blankDomainDealerData = {
    id: null,
    name: null,
    domain_name_agent_id: null,
  }

  const blankDomainAgent = {
    id: null,
    agent: null,
  }

  const getDomainAgentData = callback => {
    getMetaListData({ key: 'domainNameAgent', queryParams: { noPagination: true } })
      .then(response => {
        const { data } = response.data.data
        domainAgentOptions.value = data.map(item => {
          const resolve = {
            ...syncObject(blankDomainAgent, item),
          }
          return resolve
        })
        callback()
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
        callback()
      })
  }

  const getDomainDealerListData = () => {
    isTableBusy.value = true
    getDomainAgentData(() => {
      const resolveFilters = {
        search: searchQuery.value,
        limit: perPage.value,
        page: currentPage.value,
        sort: sortBy.value,
        order: isSortDirDesc.value ? 'desc' : 'asc',
        offset: dataMeta.value.from,
        // filters: resolveFilters,
        _: Date.now(),
      }
      getMetaListData({ key: 'domainNameDealer', queryParams: { filters: resolveFilters, pagination: true } })
        .then(response => {
          const { data, total } = response.data.data
          const resolveData = data.map(item => {
            const resolve = {
              ...syncObject(blankDomainDealerData, item),
            }
            return resolve
          })
          tableData.value = resolveData
          totalNum.value = total
          isTableBusy.value = false
        })
        .catch(error => {
          useHttpCodeAlert(error.response)
        })
    })
  }

  const refetchTable = () => {
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else getDomainDealerListData()
  }

  watch([currentPage, perPage, searchQuery], () => {
    getDomainDealerListData()
  })

  return {
    tableData,
    domainAgentOptions,
    isTableBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    refetchTable,
    getDomainDealerListData,
    useAlertToast,
    useHttpCodeAlert,
  }
}

export const useAdminBranchModal = () => {
  const tableData = ref([])
  const isTableBusy = ref(true)

  const {
    syncObject,
    getMetaListData,
  } = useModalSetting()

  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  tableColumns.value = [
    { label: '#編號', key: 'id' },
    { label: '名稱', key: 'name' },
    { label: '設定', key: 'use' },
  ]

  const blankBranchData = {
    id: null,
    name: null,
    icon: null,
    branch: null,
    payment: null,
  }

  perPage.value = 8

  const getBranchListData = () => {
    isTableBusy.value = true
    getMetaListData({
      key: 'branch',
      queryParams: {
        pagination: true,
        search: searchQuery.value,
        limit: perPage.value,
        page: currentPage.value,
        sort: sortBy.value,
        order: isSortDirDesc.value ? 'desc' : 'asc',
        offset: dataMeta.value.from,
        _: Date.now(),
      },
    })
      .then(response => {
        const { data, total } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankBranchData, item),
          }
          return resolve
        })
        tableData.value = resolveData
        totalNum.value = total
        isTableBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else getBranchListData()
  }

  watch([currentPage, perPage, searchQuery], () => {
    getBranchListData()
  })

  return {
    blankBranchData,
    tableData,
    isTableBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    refetchTable,
    getBranchListData,
    useAlertToast,
    useHttpCodeAlert,
  }
}

export const useAdminCustomerModal = () => {
  const tableData = ref([])
  const isTableBusy = ref(true)

  const {
    syncObject,
    getMetaListData,
  } = useModalSetting()

  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const searchBranch = ref(null)

  tableColumns.value = [
    { label: '#編號', key: 'id' },
    { label: '名稱', key: 'name' },
    { label: '設定', key: 'use' },
  ]

  const blankCustomerData = {
    id: null,
    ssid: null,
    branch_id: null,
    image: null,
    family_name: null,
    name: null,
    account: null,
  }

  perPage.value = 8

  const getCustomerListData = () => {
    isTableBusy.value = true
    // 整理filters
    let resolveFilters = ''

    if (searchBranch.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};branch_id: ${searchBranch.value}`
      } else resolveFilters = `branch_id: ${searchBranch.value}`
    }

    getMetaListData({
      key: 'customer',
      queryParams: {
        pagination: true,
        search: searchQuery.value,
        limit: perPage.value,
        page: currentPage.value,
        sort: sortBy.value,
        order: isSortDirDesc.value ? 'desc' : 'asc',
        offset: dataMeta.value.from,
        filters: resolveFilters,
        _: Date.now(),
      },
    })
      .then(response => {
        const { data, total } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankCustomerData, item),
          }
          return resolve
        })
        tableData.value = resolveData
        totalNum.value = total
        isTableBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else getCustomerListData()
  }

  watch([currentPage, perPage, searchQuery, searchBranch], () => {
    getCustomerListData()
  })

  return {
    blankCustomerData,
    tableData,
    isTableBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    searchBranch,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    refetchTable,
    getCustomerListData,
    useAlertToast,
    useHttpCodeAlert,
  }
}
