<template>
  <div>
    <b-modal
      :id="domainDealerExplorerId"
      no-close-on-backdrop
      no-close-on-esc
      centered
      header-bg-variant="primary"
      body-class="p-0"
      :aria-hidden="false"
      @hidden="handleHide"
      @close="handleHide"
    >
      <!-- size="lg" -->
      <!-- @change="chgModalState" -->
      <template #modal-title>
        <h4 class="m-0 model-header">
          經銷商設定
        </h4>
      </template>

      <div>
        <div class="m-2">
          <b-row>
            <!-- 顯示個數 -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="table-perPage-select"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select>
            </b-col>

            <!-- 搜尋框 -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="text-nowrap d-flex justify-content-end align-items-center mt-50">

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="重新整理"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="refetchTable"
                >
                  <b-img
                    src="/dashboard/admin/images/table/refresh.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="篩選器"
                  class="d-flex align-items-center actions-link-btn ml-25"
                >
                  <b-dropdown
                    variant="link"
                    toggle-class="p-0"
                    no-caret
                    right
                  >
                    <template #button-content>
                      <b-img
                        src="/dashboard/admin/images/table/filter.svg"
                        class="actions-link-btn-image"
                        rounded
                      />
                    </template>

                    <b-dropdown-form style="min-width: 300px">
                      <b-form-group>
                        <label class="mb-50">
                          搜尋
                        </label>

                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="SearchIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            v-model="searchQuery"
                            debounce="500"
                            class="d-inline-block"
                            placeholder="搜尋..."
                          />
                          <b-input-group-append
                            v-if="searchQuery"
                            is-text
                          >
                            <feather-icon
                              icon="XIcon"
                              class="cursor-pointer text-muted"
                              @click="searchQuery = null"
                            />
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-dropdown-form>

                  </b-dropdown>
                </div>
              </div>
            </b-col>

          </b-row>
        </div>

        <!-- 列表 -->
        <b-table
          ref="refDataListTable"
          :items="tableData"
          :fields="tableColumnsFilter"
          responsive
          striped
          hover
          primary-key="id"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          show-empty
          sort-by.sync="id"
          :busy="isTableBusy"
          class="m-0 position-relative"
          :stacked="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          <!-- 忙碌中 -->
          <template #table-busy>
            <b-skeleton-table
              :rows="5"
              :columns="tableColumns.length"
              :table-props="{ bordered: true, striped: true }"
            />
          </template>

          <!-- 查無資料 -->
          <template #empty>
            <div class="text-center my-5 animate__animated animate__fadeIn">
              <b-img
                :src="$store.state.app.themeImages.notFoundImg"
                fluid
                width="480"
                alt="查無資料"
              />
            </div>
          </template>

          <!-- 欄位: 編號 -->
          <template #cell(id)="data">
            <div>
              <span>{{ data.item.id }}</span>
            </div>
          </template>

          <!-- 欄位: 名稱 -->
          <template #cell(name)="data">
            <div class="table-title">
              <b-link
                class="font-weight-bold d-block text-nowrap mb-0"
              >
                <div class="item-description">
                  {{ data.item.name }}
                </div>
              </b-link>
              <small class="text-muted">{{ resolveDomainAgentId(data.item.domain_name_agent_id) }}</small>
            </div>
          </template>

          <!-- 欄位: 目前設定 -->
          <template #cell(use)="data">
            <div>
              <feather-icon
                v-if="selectDomainDealer.id === data.item.id"
                icon="CheckCircleIcon"
                size="20"
                class="align-middle text-success font-weight-bolder"
              />

              <feather-icon
                v-else
                icon="CheckCircleIcon"
                size="20"
                class="align-middle text-body text-muted"
                style="opacity: 0.5;"
                @click="() => {
                  selectDomainDealer.id = data.item.id
                  selectDomainDealer.name = data.item.name
                  selectDomainDealer.domain_name_agent_id = data.item.domain_name_agent_id
                }"
              />
            </div>
          </template>
        </b-table>

        <!-- 顯示頁數/分頁 -->
        <div class="mx-2 my-1">
          <b-row>
            <!-- 顯示頁數 -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">第 {{ dataMeta.from }} - {{ dataMeta.to }} 筆，共 {{ dataMeta.of }} 筆</span>
            </b-col>

            <!-- 分頁 -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalNum"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>

      <template #modal-footer>
        <div>
          <b-button
            v-if="useCallbackData"
            variant="primary"
            :disabled="isTableBusy"
            @click="handleOk"
          >
            <!--  || (botInfo.id === null && selectBotData.id === null) -->
            <span v-if="!isTableBusy">確認</span>

            <b-spinner
              v-else
              small
            />
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, VBTooltip, BTable, BButton, BSkeletonTable, BSpinner, BRow, BCol, BLink, BPagination, BImg,
  BDropdown, BDropdownForm, BFormInput, BInputGroupPrepend, BInputGroup, BFormGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

// import router from '@/router'
// import store from '@/store'
import { useAdminDomainDealerModal, useModalSetting } from '../useModal'
import { useSwalToast } from '@/libs/mixins/index'

export default {
  components: {
    BModal,
    BImg,
    BRow,
    BCol,
    BLink,
    BTable,
    BButton,
    BPagination,
    BSkeletonTable,
    BSpinner,
    BDropdown,
    BDropdownForm,
    BFormInput,
    BInputGroupPrepend,
    BInputGroup,
    BFormGroup,
    BInputGroupAppend,
    vSelect,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  props: {
    isHidenEmit: {
      type: Boolean,
      default: false,
    },
    useCallbackData: {
      type: Boolean,
      default: false,
    },
    domainDealerExplorerId: {
      type: String,
      default: 'domain-dealer-explorer-modal',
    },
  },
  data() {
    return {
      selectDomainDealer: this.blankData,
    }
  },
  computed: {
    tableColumnsFilter() {
      return this.tableColumns
    },
  },
  methods: {
    // (轉換)第三方支付名稱
    resolveDomainAgentId(id) {
      const item = this.domainAgentOptions.find(f => f.id === id)
      return item ? item.agent : id
    },

    // 按下彈窗選擇
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.isTableBusy) return
      let responseText = ''
      if (this.selectDomainDealer.id) {
        responseText = `是否要將經銷商設定為 ${this.selectDomainDealer.name}?`
      }
      if (this.selectDomainDealer.id === null) {
        this.useAlertToast(false, '經銷商不得為空')
        return
      }

      this.useSwalAlertInfo('經銷商設定', responseText)
        .then(result => {
          if (result.value) {
            const agentIdInfo = this.domainAgentOptions.find(f => f.id === this.selectDomainDealer.domain_name_agent_id)
            if (this.useCallbackData) this.$emit('call-back-data', this.selectDomainDealer, agentIdInfo)
            this.$nextTick(() => {
              this.$bvModal.hide(this.domainDealerExplorerId)
              if (this.isHidenEmit) this.$emit('emit-hidden-function')
            })
          }
        })
    },

    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.isBusy) return
      this.$nextTick(() => {
        this.$bvModal.hide(this.domainDealerExplorerId)
        if (this.isHidenEmit) this.$emit('emit-hidden-function')
        this.searchQuery = null
      })
    },

    // (獲取資料)
    getData(item) {
      if (item) {
        const resolveData = {
          ...this.syncObject(this.blankData, item),
        }
        this.selectDomainDealer = resolveData
      }
      this.isTableBusy = true
      this.$bvModal.show(this.domainDealerExplorerId)
      this.getDomainDealerListData()
    },
  },
  setup() {
    // 註冊模組
    // const MODAL_BRAND_ADMIN_STORE_MODULE_NAME = 'admin-brand-modal'

    // if (!store.hasModule(MODAL_BRAND_ADMIN_STORE_MODULE_NAME)) store.registerModule(MODAL_BRAND_ADMIN_STORE_MODULE_NAME, useStoreModule)

    // onUnmounted(() => {
    //   if (store.hasModule(MODAL_BRAND_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(MODAL_BRAND_ADMIN_STORE_MODULE_NAME)
    // })

    const blankData = {
      id: null,
      name: null,
      domain_name_agent_id: null,
    }

    const {
      tableData,
      domainAgentOptions,
      isTableBusy,
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchTable,

      getDomainDealerListData,
      useAlertToast,
      useHttpCodeAlert,
    } = useAdminDomainDealerModal()

    const {
      syncObject,
      getMetaListData,
      brandsOptions,
    } = useModalSetting()

    return {
      blankData,
      tableData,
      domainAgentOptions,
      isTableBusy,
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchTable,

      getDomainDealerListData,
      getMetaListData,
      syncObject,
      brandsOptions,
      useAlertToast,
      useHttpCodeAlert,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}

.item-description {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}
</style>
