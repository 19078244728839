<template>
  <div class="vue-load-image">
    <div
      v-if="status === 'loaded'"
      class="animate__animated animate__fadeIn"
    >
      <slot name="image" />
    </div>

    <div
      v-if="useFailed"
      v-show="status === 'failed'"
      class="image-area"
      :style="{'min-height': blankHeight }"
    >
      <b-img
        :src="$store.state.app.themeImages.noImg"
        fluid
        rounded
        :width="failedWidth"
      />
    </div>

    <div
      v-if="useLoading"
      v-show="status === 'loading'"
      class="image-area"
      :style="{'min-height': blankHeight }"
    >
      <b-img
        :src="$store.state.app.themeImages.loadingImg"
        rounded
        :height="loadingHeight"
        :width="loadingWidth"
      />
    </div>
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'

const Status = {
  PENDING: 'pending',
  LOADING: 'loading',
  LOADED: 'loaded',
  FAILED: 'failed',
}

export default {
  components: {
    BImg,
  },
  props: {
    blankHeight: {
      type: String,
      default: '0',
    },
    useLoading: {
      type: Boolean,
      default: false,
    },
    useFailed: {
      type: Boolean,
      default: false,
    },
    loadingWidth: {
      type: Number,
      default: 30,
    },
    loadingHeight: {
      type: Number,
      default: 30,
    },
    failedWidth: {
      type: Number,
      default: 120,
    },
  },
  data() {
    return {
      status: null,
      img: null,
      src: null,
    }
  },
  watch: {
    src(value) {
      this.status = value ? Status.LOADING : Status.PENDING
    },
  },
  created() {
    const { attrs } = this.$slots.image[0].data
    this.src = attrs.src || attrs['data-src']
    if (this.src) {
      this.status = Status.LOADING
      this.crossOrigin = attrs.crossorigin
      this.createLoader()
      return
    }
    this.status = Status.PENDING
  },
  updated() {
    const { attrs } = this.$slots.image[0].data
    const receivedSrc = attrs.src || attrs['data-src']
    if (this.status === Status.LOADING && !this.img) {
      this.createLoader()
      return
    }
    if (this.src !== receivedSrc) {
      this.src = receivedSrc
      this.crossOrigin = attrs.crossorigin
      this.createLoader()
    }
  },
  methods: {
    createLoader() {
      this.destroyLoader()
      this.img = new Image()
      this.img.onload = this.handleLoad
      this.img.onerror = this.handleError
      this.img.crossOrigin = this.crossOrigin
      this.img.src = this.src
    },
    destroyLoader() {
      if (this.img) {
        this.img.onload = null
        this.img.onerror = null
        this.img = null
      }
    },
    handleLoad() {
      this.destroyLoader()
      this.status = Status.LOADED
      this.$emit('onLoad')
    },
    handleError(error) {
      this.destroyLoader()
      this.status = Status.FAILED
      this.$emit('onError', error)
    },
  },
}
</script>

<style lang="scss" scoped>
.image-area {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
