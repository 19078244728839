<template>
  <div
    v-if="$store.state['admin-domain-price'].domainPriceInfo"
  >
    <h4 class="font-weight-bolder">
      目前網域: <span class="server-name">{{ adminDomainState.domainPriceInfo.suffix }}</span>
    </h4>

    <b-row class="mb-2 mb-sm-0">
      <b-col
        cols="12"
        xl="10"
        lg="9"
      >
        <b-card>
          <b-row>
            <b-col
              cols="12"
              lg="7"
              md="6"
              class="mb-1"
            >
              <!-- SSID -->
              <b-form-group
                label="SSID"
                label-for="ssid"
                label-cols="4"
                label-cols-lg="5"
                label-cols-xl="4"
                class="edit-col-from-group"
              >
                <div class="mx-50">
                  {{ adminDomainState.domainPriceInfo.ssid ? adminDomainState.domainPriceInfo.ssid : '---' }}
                </div>
              </b-form-group>

              <!-- 是否為免費網域 -->
              <b-form-group
                label="是否為免費網域"
                label-for="is_free"
                label-cols="4"
                label-cols-lg="5"
                label-cols-xl="4"
                class="edit-col-from-group"
              >
                <div class="mx-50">
                  {{ adminDomainState.domainPriceInfo.is_free ? '是' : '否' }}
                </div>
              </b-form-group>

              <!-- 經銷商 -->
              <b-form-group
                label="經銷商"
                label-for="ssid"
                label-cols="4"
                label-cols-lg="5"
                label-cols-xl="4"
                class="edit-col-from-group"
              >
                <div
                  class="table-col text-nowrap cursor-pointer edit-col-label"
                  @click="() => { $refs.adminDomainDealerExplorerModal.getData({ ...adminDomainState.domainPriceInfo.domain_name_dealer_info, domain_name_agent_id: adminDomainState.domainPriceInfo.domain_name_agent_id }) }"
                >
                  <div>
                    <span>{{ adminDomainState.domainPriceInfo.domain_name_agent_info.agent ? adminDomainState.domainPriceInfo.domain_name_agent_info.agent : '---' }}</span>
                    <span
                      v-if="adminDomainState.domainPriceInfo.domain_name_dealer_info.id"
                      v-b-tooltip.hover.focus.v-secondary
                      :title="adminDomainState.domainPriceInfo.domain_name_dealer_info.name"
                      class="ml-50"
                    >
                      <b-img
                        :src="$store.state.app.themeImages.infoImg"
                        height="22"
                        width="22"
                      />
                    </span>
                  </div>
                </div>
              </b-form-group>

              <!-- 熱銷狀態 -->
              <b-form-group
                label="熱銷狀態"
                label-for="display"
                label-cols="4"
                label-cols-lg="5"
                label-cols-xl="4"
                class="edit-col-from-group"
              >
                <b-input-group
                  v-if="editCol === 'is_fire'"
                  class="input-group-merge"
                >
                  <v-select
                    v-model="domainData.is_fire"
                    :options="isFireOptions"
                    :reduce="option => option.value"
                    :clearable="false"
                    class="edit-col-select"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        沒有<em>{{ search }}</em> 的相關結果
                      </template>

                      <div v-else>
                        <small>暫無資料</small>
                      </div>
                    </template>
                  </v-select>

                  <b-input-group-append is-text>
                    <div>
                      <b-img
                        :src="require('@/assets/images/pages/ui/blue-line/checked-blue.svg')"
                        width="18"
                        class="mr-75 cursor-pointer"
                        @click="confirmEditCol"
                      />

                      <b-img
                        :src="require('@/assets/images/pages/ui/blue-line/close-blue.svg')"
                        width="18"
                        class="cursor-pointer"
                        @click="cancelEditCol"
                      />
                    </div>
                  </b-input-group-append>
                </b-input-group>

                <div
                  v-else
                  class="edit-col-label"
                  @click="selectEditCol('is_fire')"
                >
                  <span v-if="domainData.is_fire !== null">
                    <!-- {{ ui.isFireList }} / {{ domainData.is_fire }} -->
                    {{ ui.isFireList[domainData.is_fire] }}
                  </span>

                  <span
                    v-else
                    class="text-muted"
                  >
                    尚未設定
                  </span>

                  <span class="selection-btn-icon">
                    <b-img
                      :src="require('@/assets/images/pages/ui/blue-line/pen.svg')"
                      height="15"
                      width="15"
                      class="cursor-pointer"
                    />
                  </span>
                </div>
              </b-form-group>

              <!-- 身分驗證 -->
              <b-form-group
                label="身分驗證"
                label-for="require_kyc"
                label-cols="4"
                label-cols-lg="5"
                label-cols-xl="4"
                class="edit-col-from-group"
              >
                <b-input-group
                  v-if="editCol === 'require_kyc'"
                  class="input-group-merge"
                >
                  <v-select
                    v-model="domainData.require_kyc"
                    :options="kycRequiredOptions"
                    :reduce="option => option.value"
                    :clearable="false"
                    class="edit-col-select"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        沒有<em>{{ search }}</em> 的相關結果
                      </template>

                      <div v-else>
                        <small>暫無資料</small>
                      </div>
                    </template>
                  </v-select>

                  <b-input-group-append is-text>
                    <div>
                      <b-img
                        :src="require('@/assets/images/pages/ui/blue-line/checked-blue.svg')"
                        width="18"
                        class="mr-75 cursor-pointer"
                        @click="confirmEditCol"
                      />

                      <b-img
                        :src="require('@/assets/images/pages/ui/blue-line/close-blue.svg')"
                        width="18"
                        class="cursor-pointer"
                        @click="cancelEditCol"
                      />
                    </div>
                  </b-input-group-append>
                </b-input-group>

                <div
                  v-else
                  class="edit-col-label"
                  @click="selectEditCol('require_kyc')"
                >
                  <span v-if="domainData.require_kyc !== null">
                    {{ ui.kycRequired[domainData.require_kyc] }}
                  </span>

                  <span
                    v-else
                    class="text-muted"
                  >
                    尚未設定
                  </span>

                  <span class="selection-btn-icon">
                    <b-img
                      :src="require('@/assets/images/pages/ui/blue-line/pen.svg')"
                      height="15"
                      width="15"
                      class="cursor-pointer"
                    />
                  </span>
                </div>
              </b-form-group>

              <!-- kycRequiredOptions require_kyc, -->

              <!-- 上架狀態 -->
              <b-form-group
                label="上架狀態"
                label-for="display"
                label-cols="4"
                label-cols-lg="5"
                label-cols-xl="4"
                class="edit-col-from-group"
              >
                <b-input-group
                  v-if="editCol === 'display'"
                  class="input-group-merge"
                >
                  <v-select
                    v-model="domainData.display"
                    :options="displayOptions"
                    :reduce="option => option.value"
                    :clearable="false"
                    class="edit-col-select"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        沒有<em>{{ search }}</em> 的相關結果
                      </template>

                      <div v-else>
                        <small>暫無資料</small>
                      </div>
                    </template>
                  </v-select>

                  <b-input-group-append is-text>
                    <div>
                      <b-img
                        :src="require('@/assets/images/pages/ui/blue-line/checked-blue.svg')"
                        width="18"
                        class="mr-75 cursor-pointer"
                        @click="confirmEditCol"
                      />

                      <b-img
                        :src="require('@/assets/images/pages/ui/blue-line/close-blue.svg')"
                        width="18"
                        class="cursor-pointer"
                        @click="cancelEditCol"
                      />
                    </div>
                  </b-input-group-append>
                </b-input-group>

                <div
                  v-else
                  class="edit-col-label"
                  @click="selectEditCol('display')"
                >
                  <span v-if="domainData.display !== null">
                    {{ ui.displayList[0][domainData.display] }}
                  </span>

                  <span
                    v-else
                    class="text-muted"
                  >
                    尚未設定
                  </span>

                  <span class="selection-btn-icon">
                    <b-img
                      :src="require('@/assets/images/pages/ui/blue-line/pen.svg')"
                      height="15"
                      width="15"
                      class="cursor-pointer"
                    />
                  </span>
                </div>
              </b-form-group>

              <!-- 轉移費用 -->
              <b-form-group
                label="轉移費用"
                label-for="name"
                label-cols="4"
                label-cols-lg="5"
                label-cols-xl="4"
                class="edit-col-from-group"
              >
                <b-input-group
                  v-if="editCol === 'transfer'"
                  class="input-group-merge"
                >
                  <b-form-input
                    id="transfer"
                    v-model="domainData.transfer"
                    type="number"
                    :placeholder="editColData ? editColData : '請輸入轉移費用'"
                  />

                  <b-input-group-append is-text>
                    <div>
                      <b-img
                        :src="require('@/assets/images/pages/ui/blue-line/checked-blue.svg')"
                        width="18"
                        class="mr-75 cursor-pointer"
                        @click="confirmEditCol"
                      />

                      <b-img
                        :src="require('@/assets/images/pages/ui/blue-line/close-blue.svg')"
                        width="18"
                        class="cursor-pointer"
                        @click="cancelEditCol"
                      />
                    </div>
                  </b-input-group-append>
                </b-input-group>

                <div
                  v-else
                  class="edit-col-label"
                  @click="selectEditCol('transfer')"
                >
                  <span>
                    {{ domainData.transfer ? `$ ${parseInt(domainData.transfer).toLocaleString()}` : '---' }}
                  </span>

                  <span class="selection-btn-icon">
                    <b-img
                      :src="require('@/assets/images/pages/ui/blue-line/pen.svg')"
                      height="15"
                      width="15"
                      class="cursor-pointer"
                    />
                  </span>
                </div>
              </b-form-group>

              <!-- 上次更新 -->
              <b-form-group
                label="上次更新"
                label-for="updated_at"
                label-cols="4"
                label-cols-lg="5"
                label-cols-xl="4"
                class="edit-col-from-group"
              >
                <div class="mx-50">
                  {{ adminDomainState.domainPriceInfo.updated_at ? moment(adminDomainState.domainPriceInfo.updated_at).format("YYYY-MM-DD HH:mm") : '---' }}
                </div>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              lg="5"
              md="6"
            >
              <h4 class="mb-0">
                目前統計
              </h4>
              <vue-apex-charts
                type="radialBar"
                height="250"
                class="mt-2"
                :options="goalOverviewRadialBar"
                :series="[percentage]"
              />
              <b-row class="text-center mx-0">
                <b-col
                  cols="6"
                  class="border-right d-flex align-items-between flex-column pt-1"
                >
                  <b-card-text class="text-muted mb-0">
                    目標
                  </b-card-text>
                  <h3 class="font-weight-bolder mb-0">
                    {{ adminDomainState.domainPriceInfo.target ? parseInt(adminDomainState.domainPriceInfo.target).toLocaleString() : 0 }}
                  </h3>
                </b-col>

                <b-col
                  cols="6"
                  class="d-flex align-items-between flex-column pt-1"
                >
                  <b-card-text class="text-muted mb-0">
                    目前擁有數量
                  </b-card-text>
                  <h3 class="font-weight-bolder mb-0">
                    {{ parseInt(adminDomainState.domainPriceInfo.total, 10).toLocaleString() }}
                  </h3>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        xl="2"
        lg="3"
      >
        <div>
          <b-row>
            <b-col
              cols="12"
              lg="12"
              md="6"
              sm="6"
            >
              <b-link
                class="extra-link-image mb-1"
                href="https://www.webnic.cc/zh/home_tw/"
                target="_blank"
              >
                <!-- @click="showImgs(data.item.icon)" -->
                <VueLoadImage
                  :use-loading="true"
                  :use-failed="false"
                  :blank-height="'30px'"
                  :loading-width="30"
                  :loading-height="30"
                  :failed-width="100"
                >
                  <b-img
                    slot="image"
                    src="/dashboard/admin/images/pages/domain/webnic_logo.png"
                    class="title-image"
                    fluid
                    alt="webnic"
                  />
                  <!-- ? data.item.image : $store.state.app.themeImages.emptyImg -->
                </VueLoadImage>
              </b-link>
            </b-col>

            <b-col
              cols="12"
              lg="12"
              md="6"
              sm="6"
            >
              <b-link
                class="extra-link-image"
                href="https://internetbs.net/zh/index.html"
                target="_blank"
              >
                <!-- @click="showImgs(data.item.icon)" -->
                <VueLoadImage
                  :use-loading="true"
                  :use-failed="false"
                  :blank-height="'30px'"
                  :loading-width="30"
                  :loading-height="30"
                  :failed-width="100"
                >
                  <b-img
                    slot="image"
                    src="/dashboard/admin/images/pages/domain/ibs-logo.png"
                    class="title-image"
                    fluid
                    alt="ibs"
                  />
                </VueLoadImage>
              </b-link>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>

    <div class="d-flex justify-content-between">
      <h4 class="mb-0 font-weight-bolder">
        價格設定
      </h4>

      <div class="text-nowrap d-flex justify-content-end">
        <div
          v-b-tooltip.hover.focus.v-secondary
          title="重新整理"
          class="d-flex align-items-center actions-link-btn ml-25"
          @click="submitPriceRefresh"
        >
          <b-img
            src="/dashboard/admin/images/table/refresh.svg"
            class="actions-link-btn-image"
          />
        </div>

        <div
          v-b-tooltip.hover.focus.v-secondary
          title="儲存"
          class="d-flex align-items-center actions-link-btn ml-25"
          @click="submitPriceUpdate"
        >
          <b-img
            src="/dashboard/admin/images/table/save.svg"
            class="actions-link-btn-image"
          />
        </div>
      </div>
    </div>

    <b-row class="match-height">
      <!-- 新註冊價格 -->
      <b-col
        cols="12"
        xl="3"
        lg="6"
        md="6"
        class="mb-1"
      >
        <b-row>
          <b-col class="d-flex justify-content-start align-items-center">
            <h5 class="m-0">
              新註冊
            </h5>
          </b-col>

          <b-col class="d-flex justify-content-end">
            <b-dropdown
              variant="link"
              no-caret
              right
            >
              <template #button-content>
                <feather-icon
                  icon="MoreHorizontalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                class="dropdown-item-area animate__animated animate__fadeIn"
                @click="copyArray('price_new')"
              >
                <!-- <feather-icon icon="CopyIcon" />
                <span class="align-middle ml-50">複製</span> -->
                <b-img
                  :src="require('@/assets/images/pages/ui/blue-line/copy.svg')"
                  class="dropdown-item-btn-image mr-50"
                  rounded
                />
                <span>複製</span>
              </b-dropdown-item>

              <b-dropdown-item
                class="dropdown-item-area animate__animated animate__fadeIn"
                @click="pasteArray('price_new')"
              >
                <!-- <feather-icon icon="UploadIcon" />
                <span class="align-middle ml-50">匯入</span> -->

                <b-img
                  :src="require('@/assets/images/pages/ui/blue-line/paste.svg')"
                  class="dropdown-item-btn-image mr-50"
                  rounded
                />
                <span>匯入</span>
              </b-dropdown-item>
            </b-dropdown>

          </b-col>
        </b-row>

        <b-card no-body>
          <price-setting-table
            type="price_new"
            :price-array="domainData.price_new"
            @sort-array="sortArray"
            @remove-price="removePrice"
          />

          <div class="border-top">
            <b-button
              variant="flat-primary"
              block
              @click="addPrice('price_new')"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              <span class="align-middle">新增</span>
            </b-button>
          </div>

        </b-card>
      </b-col>

      <!-- 攜入(轉移)價格 -->
      <b-col
        cols="12"
        xl="3"
        lg="6"
        md="6"
        class="mb-1"
      >
        <b-row>
          <b-col class="d-flex justify-content-start align-items-center">
            <h5 class="m-0">
              攜入(轉移)
            </h5>
          </b-col>
          <b-col
            class="d-flex justify-content-end"
          >
            <b-dropdown
              variant="link"
              no-caret
              right
            >
              <template #button-content>
                <feather-icon
                  icon="MoreHorizontalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                class="dropdown-item-area animate__animated animate__fadeIn"
                @click="copyArray('price_trans')"
              >
                <b-img
                  :src="require('@/assets/images/pages/ui/blue-line/copy.svg')"
                  class="dropdown-item-btn-image mr-50"
                  rounded
                />
                <span>複製</span>
              </b-dropdown-item>

              <b-dropdown-item
                class="dropdown-item-area animate__animated animate__fadeIn"
                @click="pasteArray('price_trans')"
              >
                <b-img
                  :src="require('@/assets/images/pages/ui/blue-line/paste.svg')"
                  class="dropdown-item-btn-image mr-50"
                  rounded
                />
                <span>匯入</span>
              </b-dropdown-item>
            </b-dropdown>

          </b-col>
        </b-row>

        <b-card no-body>
          <price-setting-table
            type="price_trans"
            :price-array="domainData.price_trans"
            @sort-array="sortArray"
            @remove-price="removePrice"
          />

          <div class="border-top">
            <b-button
              variant="flat-primary"
              block
              @click="addPrice('price_trans')"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              <span class="align-middle">新增</span>
            </b-button>
          </div>

        </b-card>
      </b-col>

      <!-- 續約價格 -->
      <b-col
        cols="12"
        xl="3"
        lg="6"
        md="6"
        class="mb-1"
      >
        <b-row>
          <b-col class="d-flex justify-content-start align-items-center">
            <h5 class="m-0">
              續約
            </h5>
          </b-col>
          <b-col
            class="d-flex justify-content-end"
          >
            <b-dropdown
              variant="link"
              no-caret
              right
            >
              <template #button-content>
                <feather-icon
                  icon="MoreHorizontalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                class="dropdown-item-area animate__animated animate__fadeIn"
                @click="copyArray('price_continue')"
              >
                <b-img
                  :src="require('@/assets/images/pages/ui/blue-line/copy.svg')"
                  class="dropdown-item-btn-image mr-50"
                  rounded
                />
                <span>複製</span>
              </b-dropdown-item>

              <b-dropdown-item
                class="dropdown-item-area animate__animated animate__fadeIn"
                @click="pasteArray('price_continue')"
              >
                <b-img
                  :src="require('@/assets/images/pages/ui/blue-line/paste.svg')"
                  class="dropdown-item-btn-image mr-50"
                  rounded
                />
                <span>匯入</span>
              </b-dropdown-item>
            </b-dropdown>

          </b-col>
        </b-row>

        <b-card no-body>
          <price-setting-table
            type="price_continue"
            :price-array="domainData.price_continue"
            @sort-array="sortArray"
            @remove-price="removePrice"
          />

          <div class="border-top">
            <b-button
              variant="flat-primary"
              block
              @click="addPrice('price_continue')"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              <span class="align-middle">新增</span>
            </b-button>
          </div>

        </b-card>
      </b-col>

      <!-- 申購成本 -->
      <b-col
        cols="12"
        xl="3"
        lg="6"
        md="6"
        class="mb-1"
      >
        <b-row>
          <b-col class="d-flex justify-content-start align-items-center">
            <h5 class="m-0">
              申購成本
            </h5>
          </b-col>
          <b-col
            class="d-flex justify-content-end"
          >
            <b-dropdown
              variant="link"
              no-caret
              right
            >
              <template #button-content>
                <feather-icon
                  icon="MoreHorizontalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                class="dropdown-item-area animate__animated animate__fadeIn"
                @click="copyArray('cost')"
              >
                <b-img
                  :src="require('@/assets/images/pages/ui/blue-line/copy.svg')"
                  class="dropdown-item-btn-image mr-50"
                  rounded
                />
                <span>複製</span>
              </b-dropdown-item>

              <b-dropdown-item
                class="dropdown-item-area animate__animated animate__fadeIn"
                @click="pasteArray('cost')"
              >
                <b-img
                  :src="require('@/assets/images/pages/ui/blue-line/paste.svg')"
                  class="dropdown-item-btn-image mr-50"
                  rounded
                />
                <span>匯入</span>
              </b-dropdown-item>
            </b-dropdown>

          </b-col>
        </b-row>

        <b-card no-body>
          <price-setting-table
            type="cost"
            :price-array="domainData.cost"
            @sort-array="sortArray"
            @remove-price="removePrice"
          />

          <div class="border-top">
            <b-button
              variant="flat-primary"
              block
              @click="addPrice('cost')"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              <span class="align-middle">新增</span>
            </b-button>
          </div>

        </b-card>
      </b-col>
    </b-row>

    <admin-domain-dealer-modal
      ref="adminDomainDealerExplorerModal"
      domain-dealer-explorer-id="adminDomainDealerExplorerModal"
      :is-hiden-emit="false"
      :use-callback-data="true"
      @emit-hidden-function="() => {}"
      @call-back-data="callbacDomainDealerExplorer"
    />
  </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BCard, BButton, BFormGroup, BImg, BInputGroup, BFormInput, BInputGroupAppend, VBTooltip, BCardText,
  BDropdown, BDropdownItem, BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'
import store from '@/store'
import router from '@/router'
import PriceSettingTable from './PriceSettingTable.vue'
import VueLoadImage from '@/layouts/components/ImageLoader/VueLoadImage.vue'
import AdminDomainDealerModal from '@/layouts/components/Modal/admin-domain-dealer-modal/domainDealerModal.vue'
import { useDomainPrice, useDomainSetting } from '../../useDomain'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
import useStoreModule from '../../useStoreModule'

export default {
  metaInfo() {
    return {
      title: this.title ? this.title : store.state['app-config'].app.appName,
      titleTemplate: `%s - ${store.state.app.titleTemplate}`,
    }
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BFormGroup,
    BCardText,
    BImg,
    BLink,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    vSelect,
    VueApexCharts,
    PriceSettingTable,
    VueLoadImage,
    AdminDomainDealerModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  data() {
    return {
      editCol: null,
      editColData: null,
    }
  },
  computed: {
    adminDomainState() {
      return this.$store.state['admin-domain-price']
    },
    percentage() {
      return 0
      // if (Math.round((this.adminDomainState.domainPriceInfo.total / this.adminDomainState.domainPriceInfo.target) * 100) > 100) return 100
      // return Math.round((this.adminDomainState.domainPriceInfo.total / this.adminDomainState.domainPriceInfo.target) * 100)
    },
  },
  watch: {
    'adminDomainState.domainPriceInfo': {
      handler() {
        this.initDomainInfo()
      },
      deep: true,
    },
  },
  mounted() {
    if (this.adminDomainState.domainPriceInfo) {
      this.initDomainInfo()
    }
  },
  beforeDestroy() {
    this.$store.commit('admin-domain-price/UPDATE_DOMAIN_PRICE_INFO_STATE', null)
  },
  methods: {
    moment,
    // (初始化)網域資料
    initDomainInfo() {
      const resolveData = {
        ...this.syncObject(this.blankDomainData, this.adminDomainState.domainPriceInfo),
      }
      this.domainData = JSON.parse(JSON.stringify(resolveData))
    },

    // (價格)儲存
    submitPriceUpdate() {
      const fields = ['price_new', 'price_trans', 'price_continue', 'cost']

      this.isBusy = true

      this.setDomainPriceUpdate({
        id: this.adminDomainState.domainPriceInfo.id,
        data: {
          // ...this.domainData,
          ...fields.reduce((acc, field) => {
            acc[field] = this.domainData[field]
            return acc
          }, {}),
        },
      })
        .then(response => {
          this.useHttpCodeAlert(response)
          this.isBusy = false
        })
        .catch(error => {
          this.useHttpCodeAlert(error.response)
          this.isBusy = false
        })
    },
    // ----------------------------------------經銷商設定---------------------------------------------
    // (回傳)經銷商
    callbacDomainDealerExplorer(item, agent) {
      this.isBusy = true

      this.setDomainPriceUpdate({
        id: this.adminDomainState.domainPriceInfo.id,
        data: {
          domain_name_dealer_id: item.id,
        },
      })
        .then(response => {
          this.useHttpCodeAlert(response)
          const resolveData = {
            ...this.$store.state['admin-domain-price'].domainPriceInfo,
            domain_name_dealer_info: { ...item },
            domain_name_agent_info: { ...agent },
            updated_at: new Date(),
          }
          store.commit('admin-domain-price/UPDATE_DOMAIN_PRICE_INFO_STATE', resolveData)
          this.isBusy = false
        })
        .catch(error => {
          this.useHttpCodeAlert(error.response)
          this.isBusy = false
        })
    },
    // ----------------------------------------欄位設定-----------------------------------------------
    // (選取)編輯欄位
    selectEditCol(type) {
      this.editCol = type
      this.editColData = this.domainData[type]
    },

    // (取消)編輯欄位
    cancelEditCol() {
      this.domainData[this.editCol] = this.editColData
      this.editCol = null
    },

    // (確認)編輯欄位
    confirmEditCol() {
      this.isBusy = true
      const resolveData = {
        [this.editCol]: this.domainData[this.editCol],
      }

      this.setDomainPriceUpdate({
        id: this.adminDomainState.domainPriceInfo.id,
        data: {
          ...resolveData,
        },
      })
        .then(response => {
          this.useHttpCodeAlert(response)
          this.editCol = null
          this.isBusy = false
        })
        .catch(error => {
          this.useHttpCodeAlert(error.response)
          this.isBusy = false
          this.editCol = null
        })
    },
    // ----------------------------------------價格設定-----------------------------------------------
    // (排序)陣列
    sortArray(type, index) {
      this.isEdit = true
      const resolvArr = []
      this.domainData[type].forEach((item, n) => {
        if (n !== index) resolvArr.push(item)
      })
      const result = resolvArr.map(item => item.quantity).indexOf(this.domainData[type][index].quantity)
      if (result !== -1) {
        resolvArr[result].price = this.domainData[type][index].price
        this.domainData[type] = resolvArr
      }
      this.domainData[type].sort((a, b) => parseInt(a.quantity, 10) - parseInt(b.quantity, 10))
    },

    // (新增)價格
    addPrice(type) {
      const number = this.domainData[type].length > 0 ? parseInt(this.domainData[type][this.domainData[type].length - 1].quantity, 10) : 0
      let price = 1000
      if (this.domainData[type].length > 0) {
        price = parseInt(this.domainData[type][0].price, 10) * (number + 1)
      }
      const data = {
        quantity: number + 1,
        price,
      }
      this.domainData[type].push(data)
      this.isEdit = true
    },

    // (移除)價格
    removePrice(type, index) {
      this.useSwalAlertWarning('移除價格', `你確定要移除 ${this.domainData[type][index].quantity}年 的價格嗎?`)
        .then(result => {
          if (result.value) {
            this.isEdit = true
            this.domainData[type].splice(index, 1)
          }
        })
    },

    // (複製)陣列
    copyArray(type) {
      if (!navigator.clipboard) {
        this.useAlertToast(false, '當前環境不支援該功能')
        return
      }
      const copyData = JSON.stringify(this.domainData[type])
      if (!copyData) return
      navigator.clipboard.writeText(copyData)
        .then(() => {
          this.useAlertToast(true, '價格已複製')
        })
        .catch(() => {
          this.useAlertToast(false, '無法讀取剪貼板內容')
        })
    },

    // (貼上)陣列
    pasteArray(type) {
      navigator.clipboard.readText()
        .then(res => {
          const pasteData = JSON.parse(res)
          if (typeof pasteData === 'object' && pasteData.length) {
            const tableData = pasteData.map((item, index) => {
              const data = {
                quantity: item.quantity ? item.quantity : (index + 1),
                price: item.price ? item.price : '1000',
              }
              return data
            })
            this.domainData[type] = tableData
          } else this.useAlertToast(false, '資料型態不符')
        })
        .catch(() => {
          this.useAlertToast(false, '資料型態不符')
        })
    },

  },
  setup() {
    // 註冊模組
    const PRICE_DOMAIN_ADMIN_STORE_MODULE_NAME = 'admin-domain-price'

    if (!store.hasModule(PRICE_DOMAIN_ADMIN_STORE_MODULE_NAME)) store.registerModule(PRICE_DOMAIN_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(PRICE_DOMAIN_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(PRICE_DOMAIN_ADMIN_STORE_MODULE_NAME)
    })

    const domainID = router.currentRoute.params.id

    const {
      isBusy,
      domainData,
      goalOverviewRadialBar,
      getDomainPriceData,
      setDomainPriceUpdate,
    } = useDomainPrice()

    const {
      ui,
      kycRequiredOptions,
      displayOptions,
      isFireOptions,
      syncObject,
    } = useDomainSetting()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const blankDomainData = {
      is_fire: false,
      display: false,
      require_kyc: false,
      cost: [],
      price_new: [],
      price_trans: [],
      price_continue: [],
      transfer: 0,
      domain_name_dealer_id: null,
    }

    const refreshDomainPriceData = () => {
      store.commit('admin-domain-price/UPDATE_BUSY_LOADING_STATE', true)
      getDomainPriceData({ id: domainID }, () => {
        store.commit('admin-domain-price/UPDATE_BUSY_LOADING_STATE', false)
      })
    }

    const submitPriceRefresh = () => {
      store.commit('admin-domain-price/UPDATE_BUSY_LOADING_STATE', true)
      getDomainPriceData({ id: domainID }, () => {
        domainData.value = JSON.parse(JSON.stringify(blankDomainData))
        const resolveData = {
          ...syncObject(blankDomainData, store.state['admin-domain-price'].domainPriceInfo),
        }
        domainData.value = resolveData
        store.commit('admin-domain-price/UPDATE_BUSY_LOADING_STATE', false)
      })
    }

    const resetDomainData = () => {
      domainData.value = JSON.parse(JSON.stringify(blankDomainData))
      isBusy.value = false
    }

    refreshDomainPriceData()
    // resetDomainData()

    return {
      ui,
      useAlertToast,
      useHttpCodeAlert,
      kycRequiredOptions,
      displayOptions,
      isFireOptions,
      syncObject,
      domainData,
      goalOverviewRadialBar,
      blankDomainData,
      isBusy,
      resetDomainData,
      refreshDomainPriceData,
      setDomainPriceUpdate,
      submitPriceRefresh,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/chart-apex.scss';

.edit-col-from-group {
  .vs__dropdown-toggle {
    border-radius: 5px 0 0 5px;
  }
}
</style>

<style lang="scss" scoped>
.dark-layout {
  .edit-col-from-group {
    .edit-col-label {
      &:hover {
        background: #aaaaaa20;
      }
    }
  }
  .extra-link-image {
    background-color: rgb(62, 115, 184);
  }
}

.edit-col-from-group {
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  .edit-col-label {
    padding: 0 10px;
    cursor: pointer;
    border-radius: 5px;
    word-break: break-all;
    overflow: hidden;
    .selection-btn-icon {
      display: none;
    }
    &:hover {
      background: #a9a8a820;
      // box-shadow: 0 4px 4px 0 rgba(#6f6f6f, 0.25);
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 36px;
      .selection-btn-icon {
        display: inline-block;
      }
    }
  }

  .edit-col-select {
    // background-color: red;
    width: calc(100% - 80px);
  }
}

.preview-col-from-group {
  .preview-col-label {
    padding: 0 10px;
  }
}

.server-name {
  // font-size: 1.5rem;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    color: red !important;
    text-decoration: underline
  }
}

.extra-link-image {
  // margin-bottom: 10px;
  box-shadow: 0 4px 4px 0 rgba(#6f6f6f, 0.25);
  background-color: rgb(178, 212, 253);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  .title-image {
    cursor: pointer;
    max-height: 50px;
  }
  &:hover {
    // box-shadow: 0 4px 4px 0 rgba(#6f6f6f, 0.25);
    filter: brightness(calc(110 * 1%));
    transform: translateY(-5px);
    transition: .4s;
  }
}

.dropdown-item-area {
  transition: transform ease-out 200ms;
  // display: flex;
  // align-items: center;
  .dropdown-item-btn-image {
    width: 14px;
    height: 14px;
  }

  &:hover {
    cursor: pointer;
    .dropdown-item-btn-image {
      transform: scale(1.2);
      animation: btn-breathe-todo-dropdown .8s linear infinite;
    }
  }
}

@keyframes btn-breathe-todo-dropdown {
  0%{ transform: scale(0.8); }
  50%{ transform: scale(1.2); }
  100%{ transform: scale(0.8); }
}

</style>
