<template>
  <div>
    <b-table
      :items="priceArray"
      :fields="tableColumns"
      hover
      show-empty
      responsive
      class="mb-0 table-responsive"
    >
      <!-- 查無資料 -->
      <template #empty>
        <div class="my-5 text-center animate__animated animate__fadeIn table-responsive-empty">
          <b-img
            :src="$store.state.app.themeImages.notFoundImg"
            fluid
            width="300"
            alt="查無資料"
          />
        </div>
      </template>

      <!-- 年數 -->
      <template #cell(quantity)="data">
        <div class="table-col">
          <div v-if="selectRow.index === data.index && selectRow.type === 'quantity'">
            <b-input-group class="input-group-merge">
              <b-form-input
                id="price"
                v-model="data.item.quantity"
                placeholder="年數"
                type="number"
                @change="confirmEditCol"
              />

              <b-input-group-append is-text>
                <div>
                  <b-img
                    :src="require('@/assets/images/pages/ui/blue-line/checked-blue.svg')"
                    width="16"
                    class="cursor-pointer"
                    @click="confirmEditCol"
                  />
                </div>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div
            v-else
            @click="selectRowItem('quantity', data.index)"
          >
            {{ data.item.quantity != 1 ? `${data.item.quantity}年` : '首年' }}
          </div>
        </div>
      </template>

      <!-- 價格 -->
      <template #cell(price)="data">
        <div class="table-col">
          <div v-if="selectRow.index === data.index && selectRow.type === 'price'">
            <b-input-group class="input-group-merge">
              <b-form-input
                id="price"
                v-model="data.item.price"
                placeholder="1,000"
                type="number"
                @change="confirmEditCol"
              />

              <b-input-group-append is-text>
                <div>
                  <b-img
                    :src="require('@/assets/images/pages/ui/blue-line/checked-blue.svg')"
                    width="16"
                    class="cursor-pointer"
                    @click="confirmEditCol"
                  />
                </div>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div
            v-else
            @click="selectRowItem('price', data.index)"
          >
            {{ data.item.price ? parseInt(data.item.price).toLocaleString() : 0 }}
          </div>
        </div>
      </template>

      <!-- 動作 -->
      <template #cell(actions)="data">
        <div class="d-flex">
          <!-- <feather-icon
            icon="XIcon"
            class="cursor-pointer"
            @click="removeItem(data.index)"
          /> -->
          <div
            class="actions-link-btn"
            @click="removeItem(data.index)"
          >
            <b-img
              v-b-tooltip.hover.focus.v-secondary
              title="移除"
              :src="require('@/assets/images/pages/ui/blue-line/close.svg')"
              class="actions-link-btn-image"
              rounded
            />
          </div>
        </div>
      </template>
    </b-table>
  </div>
  <!-- {{ priceArray }} -->
</template>

<script>
import {
  BTable, BInputGroup, BFormInput, BInputGroupAppend, BImg, VBTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BImg,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    priceArray: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      tableColumns: [
        { label: '年數', key: 'quantity' },
        { label: '價格(TWD)', key: 'price' },
        { label: '動作', key: 'actions' },
      ],
      selectRow: {
        index: null,
        type: null,
      },
    }
  },
  methods: {
    // (選擇)欄位
    selectRowItem(type, index) {
      this.selectRow.type = type
      this.selectRow.index = index
    },

    // // (取消)編輯欄位
    // cancelEditCol() {
    //   this.selectRow.type = null
    //   this.selectRow.index = null
    // },

    // (確認)編輯欄位
    confirmEditCol() {
      this.$emit('sort-array', this.type, this.selectRow.index)
      this.selectRow.type = null
      this.selectRow.index = null
    },

    // (移除)欄位
    removeItem(index) {
      this.$emit('remove-price', this.type, index)
    },
  },
  setup() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
// .table-responsive-empty {
//   height: 280px;
// }
</style>
